import { Component, HostListener } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { HotelServicesService } from 'src/app/services/hotel-services.service';
import { NotificationService } from 'src/app/services/notification.service';
import { LogoutConfirmationComponent } from '../../logout-confirmation/logout-confirmation.component';
import { MasterDataState } from 'src/app/session/shared/store/state/master-data.state';
import { first, Observable } from 'rxjs';
import { Select } from '@ngxs/store';
import { colorObj } from 'src/app/shared/color-object';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-rate-plan',
  templateUrl: './rate-plan.component.html',
  styleUrls: ['./rate-plan.component.scss']
})
export class RatePlanComponent {

  loading: boolean = false;
  userInfo: any;
  userName: any;
  accessType: any;
  ratePlanStep1: FormGroup;
  ratePlanStep2: FormGroup;
  ratePlanStep3: FormGroup;
  @Select(MasterDataState.getMasterData) masterData$: Observable<[]>;
  roomCategories = [];
  roomTypesList = [];
  roomTypes = [];
  marketSegments = [];
  responses = [];
  noOfDays = [];
  allRoomFacilities = [];
  roomFacilities = [];
  hotelFacilities = [];
  requiredFacilities = [];
  selectedRoomTypes = [];
  serviceTaxes = [];
  cancellationPolicies = [];
  updatePlan: boolean = false;
  selectedTab = 0;
  stayLength: boolean = false;
  pricingExist: boolean = false;
  pricingNotExist: boolean = false;
  payDeposite: boolean = false;
  ifPreBook: boolean = false;
  ifNightStay: boolean = false;
  currentDate = new Date();
  minToDate: Date;
  minBlackoutToDate: Date;
  planId;
  choosenRoomTypes = [];
  choosenHotelFacilities = [];
  blackOutInfo: any = [];
  pricingInfo: any = {};
  mobile: boolean;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenWidth();
  }

  constructor(
    public dialog: MatDialog,
    public notify: NotificationService,
    private hotelService: HotelServicesService,
    private cs: ConfigurationService,
    private router: Router,
    private fb: FormBuilder,
    private datePipe: DatePipe
  ) {
    this.ratePlanStep1 = this.fb.group({
      roomCategory: ['', Validators.required],
      roomType: [[], Validators.required],
      marketSegment: ['', Validators.required],
      segmentName: ['', Validators.required],
      ratePlanName: ['', Validators.required],
      ratePlanCode: ['', Validators.required],
      rateDescription: ['', Validators.required],
      specifyStayLength: ['', Validators.required],
      minimumDays: [''],
      maximumDays: ['']
    })
    this.ratePlanStep2 = this.fb.group({
      hotelFacility: [[], Validators.required],
      derivedPlan: ['', Validators.required],
      planPricing: ['', Validators.required],
      roomTypeArray: fb.array([]),
      blackOutArray: fb.array([]),
      // derivedRoomType: [[]],
      // derivedRatePlan: [''],
      // derivedRoomDiscount: [''],
      // derivedRoomDiscountValue: [''],
      preDeposite: ['', Validators.required],
      preDepositeAmount: [''],
      preBook: ['', Validators.required],
      minPreBookDays: [''],
      maxPreBookDays: [''],
      tax: ['', Validators.required]
    })
    this.ratePlanStep3 = this.fb.group({
      cancellation: ['', Validators.required]
    })
  }

  ngOnInit() {
    this.checkScreenWidth();
    this.userInfo = JSON.parse(window.localStorage.getItem('userInfo'));
    this.userName = this.userInfo.firstName;
    this.accessType = this.userInfo.accessTypeId;
    this.getCategories();
    this.getTaxes();
    this.getCancellationPolicies();
    this.masterData$.subscribe(res => {
      if (Object.keys(res).length != 0) {
        this.roomTypes = res['ROOM_TYPE'];
        this.marketSegments = res['MARKET_SEGMENT'];
        this.responses = res['RESPONSE'];
        this.noOfDays = res['DAYS'];
        this.allRoomFacilities = res['ROOM_FACILITIES'];
        for (let ind of this.allRoomFacilities) {
          let obj = {
            "ID": ind.ID,
            "NAME": ind.NAME,
            "ENABLED": false
          }
          this.roomFacilities.push(obj);
        }
        this.hotelFacilities = res['HOTEL_FACILITIES'];
      }
    })
    this.hotelService.actionType
      .pipe(first()).subscribe(step => {
        if (!!step) {
          if (step === 'edit_plan') {
            this.hotelService.getId().subscribe((val) => {
              this.planId = val;
            });
            this.updatePlan = true;
            this.loading = true;
            this.hotelService.getRatePlanById(this.planId).subscribe({
              next: async (res) => {
                this.loading = false;
                this.ratePlanStep1.get("roomCategory").setValue(res.object.CATEGORY?.ID);
                await this.getRoomTypes(res.object.CATEGORY?.ID);
                if (!!res.object.ROOM_TYPE) {
                  for (let type of res.object.ROOM_TYPE) {
                    this.choosenRoomTypes.push(type?.ID);
                  }
                }
                this.ratePlanStep1.get("roomType").setValue(this.choosenRoomTypes);
                this.ratePlanStep1.get("marketSegment").setValue(res.object.MARKET_SEGMENT?.ID);
                this.ratePlanStep1.get("segmentName").setValue(res.object.MARKET_SEGMENT_NAME);
                this.ratePlanStep1.get("ratePlanName").setValue(res.object.NAME);
                this.ratePlanStep1.get("ratePlanCode").setValue(res.object.CODE);
                this.ratePlanStep1.get("rateDescription").setValue(res.object.DESCRIPTION);
                this.ratePlanStep1.get("specifyStayLength").setValue(res.object.STAY_CONDITION?.ID);
                if (res.object.STAY_CONDITION?.ID == 1) {
                  this.stayLength = true;
                  this.ratePlanStep1.get("minimumDays").setValue(res.object.MIN_STAY?.ID);
                  this.ratePlanStep1.get("maximumDays").setValue(res.object.MAX_STAY?.ID);
                }
                if (!!res.object.ROOM_FACILITIES) {
                  for (let facility of res.object.ROOM_FACILITIES) {
                    let requiredIndex = this.roomFacilities.findIndex(x => x.ID == facility.ID);
                    this.roomFacilities[requiredIndex]['ENABLED'] = true;
                    this.requiredFacilities.push(facility.ID);
                  }
                }
                if (!!res.object.HOTEL_FACILITIES) {
                  for (let facility of res.object.HOTEL_FACILITIES) {
                    this.choosenHotelFacilities.push(facility?.ID);
                  }
                }
                this.ratePlanStep2.get("hotelFacility").setValue(this.choosenHotelFacilities);
                this.ratePlanStep2.get("derivedPlan").setValue(res.object.DERIVED_PLAN?.ID);
                if (res.object.DERIVED_PLAN?.ID == 1) {
                  this.ratePlanStep2.controls["planPricing"].setValue(2);
                  this.pricingExist = false;
                  this.pricingNotExist = true;
                }
                else if (res.object.DERIVED_PLAN?.ID == 2) {
                  this.ratePlanStep2.controls["planPricing"].setValue(1);
                  this.pricingNotExist = false;
                  this.pricingExist = true;
                  this.pricingInfo = res.object.RATE_PLAN_PRICING;
                  if (Object.keys(this.pricingInfo).length != 0) {
                    Object.keys(this.pricingInfo).forEach(key => { //loop through the no.of room types selected
                      this.roomTypesList.forEach(type => {
                        if (key == type.ID) {
                          this.selectedRoomTypes.push(type); //selected room types stored array
                          this.addTypes(type);
                          let roomTypeInfo = [];
                          roomTypeInfo = this.pricingInfo[key];
                          if (Array.isArray(roomTypeInfo) && roomTypeInfo.length > 0) {
                            for (let i = 0; i < roomTypeInfo.length; i++) { //loop through details of each room type
                              this.addPricing(this.selectedRoomTypes.indexOf(type), 'auto', type);
                              let typeArray = (((this.ratePlanStep2.controls['roomTypeArray'] as FormArray).controls[this.selectedRoomTypes.indexOf(type)] as FormGroup).controls['pricingArray'] as FormArray) as FormArray;
                              let typeArrayObj = typeArray.controls[i] as FormGroup;
                              typeArrayObj.get('activeFrom').patchValue(this.datePipe.transform(roomTypeInfo[i]['DATE_FROM'], 'yyyy-MM-dd'));
                              typeArrayObj.get('activeTo').patchValue(this.datePipe.transform(roomTypeInfo[i]['DATE_TO'], 'yyyy-MM-dd'));
                              if (Object.keys(roomTypeInfo[i]['OCCUPANT']).length != 0) {
                                Object.entries(roomTypeInfo[i]['OCCUPANT']).forEach(([key, value]) => { //loop through occupant-price of each detail
                                  let occupArray = ((this.ratePlanStep2.controls['roomTypeArray'] as FormArray).at(this.selectedRoomTypes.indexOf(type)).get('pricingArray') as FormArray).at(i).get('occupantArray') as FormArray;
                                  let occupArrayObj = occupArray.controls[Object.keys(roomTypeInfo[i]['OCCUPANT']).indexOf(key)] as FormGroup;
                                  occupArrayObj.get('occupantNo').patchValue(key);
                                  occupArrayObj.get('occupantAmount').patchValue(value);
                                })
                              }
                            }
                          }
                        }
                      })
                    })
                  }
                  this.blackOutInfo = res.object.BLACKOUT;
                  if (Array.isArray(this.blackOutInfo) && this.blackOutInfo.length > 0) {
                    for (let i = 0; i < this.blackOutInfo.length; i++) {
                      this.addBlackOut();
                      let blackoutArray = this.ratePlanStep2.get('blackOutArray') as FormArray;
                      let blackOutArrayObj = blackoutArray.controls[i] as FormGroup;
                      blackOutArrayObj.get('blackOutName').patchValue(this.blackOutInfo[i]['BLACKOUT_NAME']);
                      blackOutArrayObj.get('blackOutFrom').patchValue(this.datePipe.transform(this.blackOutInfo[i]['BLACKOUT_DATE_FROM'], 'yyyy-MM-dd'));
                      blackOutArrayObj.get('blackOutTo').patchValue(this.datePipe.transform(this.blackOutInfo[i]['BLACKOUT_DATE_TO'], 'yyyy-MM-dd'));
                    };
                  }
                }
                this.ratePlanStep2.get("preDeposite").setValue(res.object.CHECKIN_DEPOSIT?.ID);
                if (res.object.CHECKIN_DEPOSIT?.ID == 1) {
                  this.payDeposite = true;
                  this.ratePlanStep2.get("preDepositeAmount").setValue(res.object.CHECKIN_DEPOSIT_AMOUNT);
                }
                this.ratePlanStep2.get("preBook").setValue(res.object.ADVANCED_BOOKING?.ID);
                if (res.object.ADVANCED_BOOKING?.ID == 1) {
                  this.ifPreBook = true;
                  this.ratePlanStep2.get("minPreBookDays").setValue(res.object.ADVANCED_MIN_STAY?.ID);
                  this.ratePlanStep2.get("maxPreBookDays").setValue(res.object.ADVANCED_MAX_STAY?.ID);
                }
                this.ratePlanStep2.get("tax").setValue(res.object.TAX?.ID);
                this.ratePlanStep3.get("cancellation").setValue(res.object.ROOM_CANCELLATION_POLICY?.ID);
              }
            })
          }
        }
      })
  }

  private checkScreenWidth() {
    if (window.innerWidth <= 1250) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }
  }

  getCategories() {
    this.hotelService.getRoomCategory().subscribe({
      next: (data) => {
        this.roomCategories = data.object;
      },
      error: (e) => {
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
      }
    })
  }

  selectRoomCategory(category) {
    this.getRoomTypes(category);
  }

  async getRoomTypes(categoryId) {
    await this.hotelService.getRoomTypeList(categoryId).then((data) => {
      if (data) {
        this.roomTypesList = data.object;
      }
    }).catch((err) => {
      this.notify.showNotification(
        err.error.message,
        "top",
        (!!colorObj[err.error.status] ? colorObj[err.error.status] : "error"),
        err.error.status
      )
    });
  }

  getTaxes() {
    this.cs.getTax().subscribe({
      next: (res) => {
        this.serviceTaxes = res.object;  //limit issue
      },
      error: (e) => {
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
      }
    })
  }

  getCancellationPolicies() {
    this.cs.getCancellationPolicy().subscribe({
      next: (res) => {
        if (!!res.object.CANCELLATION_POLICY && Array.isArray(res.object.CANCELLATION_POLICY)) {
          this.cancellationPolicies = res.object.CANCELLATION_POLICY;
        }
      },
      error: (e) => {
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
      }
    })
  }

  toggle(event, fieldName) {
    if (fieldName == 'specifyStayLength') {
      if (event.value == 1) {
        this.stayLength = true;
        this.ratePlanStep1.controls["minimumDays"].setValidators(Validators.required);
        this.ratePlanStep1.controls["maximumDays"].setValidators(Validators.required);
      }
      else if (event.value == 2) {
        this.stayLength = false;
        this.ratePlanStep1.get('minimumDays').clearValidators();
        this.ratePlanStep1.get('minimumDays').updateValueAndValidity();
        this.ratePlanStep1.get('maximumDays').clearValidators();
        this.ratePlanStep1.get('maximumDays').updateValueAndValidity();
        this.ratePlanStep1.controls["minimumDays"].reset();
        this.ratePlanStep1.controls["maximumDays"].reset();
      }
    }
    else if (fieldName == 'derivedPlan') {
      if (event.value == 1) {
        this.ratePlanStep2.controls["planPricing"].setValue(2);
        this.pricingExist = false;
        this.pricingNotExist = true;
        // this.ratePlanStep2.controls["derivedRoomType"].setValidators(Validators.required);
        // this.ratePlanStep2.controls["derivedRatePlan"].setValidators(Validators.required);
        // this.ratePlanStep2.controls["derivedRoomDiscount"].setValidators(Validators.required);
        // this.ratePlanStep2.controls["derivedRoomDiscountValue"].setValidators(Validators.required);
        this.selectedRoomTypes = [];
        (this.ratePlanStep2.get('roomTypeArray') as FormArray).clearValidators();
        (this.ratePlanStep2.get('roomTypeArray') as FormArray).updateValueAndValidity();
        (this.ratePlanStep2.controls['roomTypeArray'] as FormArray).clear();
        (this.ratePlanStep2.get('blackOutArray') as FormArray).clearValidators();
        (this.ratePlanStep2.get('blackOutArray') as FormArray).updateValueAndValidity();
        (this.ratePlanStep2.controls['blackOutArray'] as FormArray).clear();
      }
      else if (event.value == 2) {
        this.ratePlanStep2.controls["planPricing"].setValue(1);
        this.pricingNotExist = false;
        this.pricingExist = true;
        (this.ratePlanStep2.controls['roomTypeArray'] as FormArray).setValidators(Validators.required);
        (this.ratePlanStep2.controls['blackOutArray'] as FormArray).setValidators(Validators.required);
        this.ratePlanStep1.controls['roomType'].value.forEach(element => {
          this.roomTypesList.forEach(type => {
            if (element == type.ID) {
              this.selectedRoomTypes.push(type);
            }
          })
        });
        this.selectedRoomTypes.forEach(type => {
          this.addTypes(type);
        });
        this.addBlackOut();
        // this.ratePlanStep2.get('derivedRoomType').clearValidators();
        // this.ratePlanStep2.get('derivedRoomType').updateValueAndValidity();
        // this.ratePlanStep2.get('derivedRatePlan').clearValidators();
        // this.ratePlanStep2.get('derivedRatePlan').updateValueAndValidity();
        // this.ratePlanStep2.get('derivedRoomDiscount').clearValidators();
        // this.ratePlanStep2.get('derivedRoomDiscount').updateValueAndValidity();
        // this.ratePlanStep2.get('derivedRoomDiscountValue').clearValidators();
        // this.ratePlanStep2.get('derivedRoomDiscountValue').updateValueAndValidity();
        // this.ratePlanStep2.controls["derivedRoomType"].reset();
        // this.ratePlanStep2.controls["derivedRatePlan"].reset();
        // this.ratePlanStep2.controls["derivedRoomDiscount"].reset();
        // this.ratePlanStep2.controls["derivedRoomDiscountValue"].reset();
      }
    }
    else if (fieldName == 'preDeposite') {
      if (event.value == 1) {
        this.payDeposite = true;
        this.ratePlanStep2.controls["preDepositeAmount"].setValidators(Validators.required);
      }
      else if (event.value == 2) {
        this.payDeposite = false;
        this.ratePlanStep2.get('preDepositeAmount').clearValidators();
        this.ratePlanStep2.get('preDepositeAmount').updateValueAndValidity();
        this.ratePlanStep2.controls["preDepositeAmount"].reset();
      }
    }
    else if (fieldName == 'preBook') {
      if (event.value == 1) {
        this.ifPreBook = true;
        this.ratePlanStep2.controls["minPreBookDays"].setValidators(Validators.required);
        this.ratePlanStep2.controls["maxPreBookDays"].setValidators(Validators.required);
      }
      else if (event.value == 2) {
        this.ifPreBook = false;
        this.ratePlanStep2.get('minPreBookDays').clearValidators();
        this.ratePlanStep2.get('minPreBookDays').updateValueAndValidity();
        this.ratePlanStep2.get('maxPreBookDays').clearValidators();
        this.ratePlanStep2.get('maxPreBookDays').updateValueAndValidity();
        this.ratePlanStep2.controls["minPreBookDays"].reset();
        this.ratePlanStep2.controls["maxPreBookDays"].reset();
      }
    }
  }

  selectRoomFacilities(event, id) {
    let requiredIndex = this.roomFacilities.findIndex(x => x.ID == id);
    this.roomFacilities[requiredIndex]['ENABLED'] = event.checked;
    let requiredIdIndex = this.requiredFacilities.findIndex(x => x == id);
    if (requiredIdIndex == -1 && event.checked == true) {
      this.requiredFacilities.push(id);
    } else if (requiredIdIndex != -1 && event.checked == false) {
      this.requiredFacilities.splice(requiredIdIndex, 1);
    }
  }

  addTypes(type?: any) {
    let typeIndex = (this.ratePlanStep2.get('roomTypeArray') as FormArray).length;
    let fg = this.fb.group({
      priceTypeId: type.ID,
      pricingName: type.NAME,
      pricingArray: this.fb.array([])
    });
    (this.ratePlanStep2.get('roomTypeArray') as FormArray).push(fg);
    if (!this.updatePlan) {
      this.addPricing(typeIndex, 'auto', type);
    }
  }

  removePricing(typeIndex, index: number) {
    (((this.ratePlanStep2.controls['roomTypeArray'] as FormArray).controls[typeIndex] as FormGroup).controls['pricingArray'] as FormArray).removeAt(index);
  }

  addPricing(typeIndex, cond, type?: any) {
    let priceIndex = (((this.ratePlanStep2.controls['roomTypeArray'] as FormArray).controls[typeIndex] as FormGroup).controls['pricingArray'] as FormArray).length;
    if (priceIndex < 10) {
      let fg = this.fb.group({
        activeFrom: this.fb.control('', Validators.required),
        activeTo: this.fb.control('', Validators.required),
        occupantArray: this.fb.array([])
      });
      (((this.ratePlanStep2.controls['roomTypeArray'] as FormArray).controls[typeIndex] as FormGroup).controls['pricingArray'] as FormArray).push(fg);
    }
    if (cond == 'auto' && !!type.ADULT_OCCUPANCY) {
      for (let i = 0; i < type.ADULT_OCCUPANCY; i++) {
        this.addOccupants(typeIndex, priceIndex, type);
        let occupArray = ((this.ratePlanStep2.controls['roomTypeArray'] as FormArray).at(typeIndex).get('pricingArray') as FormArray).at(priceIndex).get('occupantArray') as FormArray;
        let occupArrayObj = occupArray.controls[i] as FormGroup;
        occupArrayObj.get('occupantNo').setValue(i + 1);
      }
    }
    else if (cond == 'manual') {
      this.roomTypesList.forEach(room => {
        if (type.value.priceTypeId == room.ID && !!room.ADULT_OCCUPANCY) {
          for (let i = 0; i < room.ADULT_OCCUPANCY; i++) {
            this.addOccupants(typeIndex, priceIndex, type);
            let occupArray = ((this.ratePlanStep2.controls['roomTypeArray'] as FormArray).at(typeIndex).get('pricingArray') as FormArray).at(priceIndex).get('occupantArray') as FormArray;
            let occupArrayObj = occupArray.controls[i] as FormGroup;
            occupArrayObj.get('occupantNo').setValue(i + 1);
          }
        }
      })
    }
  }

  addOccupants(typeIndex, index, type?: any) {
    let fg = this.fb.group({
      occupantNo: this.fb.control('', Validators.required),
      occupantAmount: this.fb.control('', Validators.required)
    });
    (((((this.ratePlanStep2.controls['roomTypeArray'] as FormArray).controls[typeIndex] as FormGroup).controls['pricingArray'] as FormArray).controls[index] as FormArray).controls['occupantArray']).push(fg);
  }

  selectDate(typeIndex, index) {
    this.minToDate = new Date((((this.ratePlanStep2.controls['roomTypeArray'] as FormArray).controls[typeIndex] as FormGroup).controls['pricingArray'] as FormArray).controls[index].value.activeFrom);
    ((((this.ratePlanStep2.controls['roomTypeArray'] as FormArray).controls[typeIndex] as FormGroup).controls['pricingArray'] as FormArray).controls[index]).get('activeTo').reset();
  }

  resetPriceDate(typeIndex, index) {
    this.minToDate = new Date((((this.ratePlanStep2.controls['roomTypeArray'] as FormArray).controls[typeIndex] as FormGroup).controls['pricingArray'] as FormArray).controls[index].value.activeFrom);
  }

  selectBlackoutDate(index) {
    this.minBlackoutToDate = new Date((this.ratePlanStep2.get('blackOutArray') as FormArray).at(index).value.blackOutFrom);
    (this.ratePlanStep2.get('blackOutArray') as FormArray).at(index).get('blackOutTo').reset();
  }

  resetBlackoutDate(index) {
    this.minBlackoutToDate = new Date((this.ratePlanStep2.get('blackOutArray') as FormArray).at(index).value.blackOutFrom);
  }

  getBlackOut() {
    return (this.ratePlanStep2.get('blackOutArray') as FormArray).controls;
  }

  removeBlackOut(index: number) {
    (this.ratePlanStep2.get('blackOutArray') as FormArray).removeAt(index);
  }

  addBlackOut() {
    let codeIndex = (this.ratePlanStep2.get('blackOutArray') as FormArray).length;
    if (codeIndex < 20) {
      let fg = this.fb.group({
        blackOutName: this.fb.control('', Validators.required),
        blackOutFrom: this.fb.control('', Validators.required),
        blackOutTo: this.fb.control('', Validators.required)
      });
      (this.ratePlanStep2.get('blackOutArray') as FormArray).push(fg);
    }
  }

  nextStep() {
    this.selectedTab += 1;
  }

  saveRatePlan() {
    this.loading = true;
    let formData: any;
    formData = this.ratePlanStep2.value;
    let roomArray = formData['roomTypeArray'];
    let roomTypePricing = {};
    for (let room of roomArray) {
      let roomPricing = [];
      for (let price of room.pricingArray) {
        let occupantObj = {};
        const formattedActiveFrom = this.datePipe.transform(price.activeFrom, 'dd-MM-yyyy');
        const formattedActiveTo = this.datePipe.transform(price.activeTo, 'dd-MM-yyyy');
        for (let occup of price.occupantArray) {
          Object.assign(occupantObj, {
            [occup.occupantNo]: occup.occupantAmount
          });
        }
        roomPricing.push({
          "DATE_FROM": formattedActiveFrom,
          "DATE_TO": formattedActiveTo,
          "OCCUPANT": occupantObj
        })
        Object.assign(roomTypePricing, {
          [room.priceTypeId]: roomPricing
        });
      }
    }
    let array = formData['blackOutArray'];
    let blackOutArray = [];
    for (let i = 0; i < array.length; i++) {
      const blackOutFrom = this.datePipe.transform(array[i].blackOutFrom, 'dd-MM-yyyy');
      const blackOutTo = this.datePipe.transform(array[i].blackOutTo, 'dd-MM-yyyy');
      blackOutArray.push({
        "NAME": array[i].blackOutName,
        "DATE_FROM": blackOutFrom,
        "DATE_TO": blackOutTo
      })
    }
    let savedRateFiles = {
      "CATEGORY_ID": this.ratePlanStep1.value.roomCategory,
      "TYPE_ID": this.ratePlanStep1.value.roomType,
      "MARKET_SEGMENT_ID": this.ratePlanStep1.value.marketSegment,
      "MARKET_SEGMENT_NAME": this.ratePlanStep1.value.segmentName,
      "NAME": this.ratePlanStep1.value.ratePlanName,
      "CODE": this.ratePlanStep1.value.ratePlanCode,
      "DESCRIPTION": this.ratePlanStep1.value.rateDescription,
      "STAY_CONDITION_ID": this.ratePlanStep1.value.specifyStayLength,
      "STAY_CONDITION": {
        "MIN_STAY_ID": !!this.ratePlanStep1.value.minimumDays ? this.ratePlanStep1.value.minimumDays : 0,
        "MAX_STAY_ID": !!this.ratePlanStep1.value.maximumDays ? this.ratePlanStep1.value.maximumDays : 0
      },
      "ROOM_FACILITIES_ID": this.requiredFacilities,
      "HOTEL_FACILITIES_ID": this.ratePlanStep2.value.hotelFacility,
      "DERIVED_PLAN_ID": this.ratePlanStep2.value.derivedPlan,
      "PRICING": roomTypePricing,
      "BLACKOUT": blackOutArray,
      "CHECKIN_DEPOSIT": {
        "CHECKIN_DEPOSIT_ID": this.ratePlanStep2.value.preDeposite,
        "CHECKIN_DEPOSIT_AMOUNT": this.ratePlanStep2.value.preDepositeAmount
      },
      "ADVANCED_BOOKING_ID": this.ratePlanStep2.value.preBook,
      "ADVANCED_BOOKING": {
        "ADVANCED_MIN_STAY_ID": !!this.ratePlanStep2.value.minPreBookDays ? this.ratePlanStep2.value.minPreBookDays : 0,
        "ADVANCED_MAX_STAY_ID": !!this.ratePlanStep2.value.maxPreBookDays ? this.ratePlanStep2.value.maxPreBookDays : 0
      },
      "TAX_ID": this.ratePlanStep2.value.tax,
      "CANCELLATION_POLICY_ID": this.ratePlanStep3.value.cancellation
    }
    this.hotelService.addRatePlan(savedRateFiles).subscribe({
      next: (data) => {
        this.loading = false;
        this.notify.showNotification(
          data.message,
          "top",
          (!!colorObj[data.status] ? colorObj[data.status.status] : "success"),
          data.status
        );
        this.backToRoomTypes();
      },
      error: (e) => {
        this.loading = false;
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
        this.backToRoomTypes();
      }
    })
  }

  updateRatePlan() {
    this.loading = true;
    let formData: any;
    formData = this.ratePlanStep2.value;
    let roomArray = formData['roomTypeArray'];
    let roomTypePricing = {};
    for (let room of roomArray) {
      let roomPricing = [];
      for (let price of room.pricingArray) {
        let occupantObj = {};
        const formattedActiveFrom = this.datePipe.transform(price.activeFrom, 'dd-MM-yyyy');
        const formattedActiveTo = this.datePipe.transform(price.activeTo, 'dd-MM-yyyy');
        for (let occup of price.occupantArray) {
          Object.assign(occupantObj, {
            [occup.occupantNo]: occup.occupantAmount
          });
        }
        roomPricing.push({
          "DATE_FROM": formattedActiveFrom,
          "DATE_TO": formattedActiveTo,
          "OCCUPANT": occupantObj
        })
        Object.assign(roomTypePricing, {
          [room.priceTypeId]: roomPricing
        });
      }
    }
    let array = formData['blackOutArray'];
    let blackOutArray = [];
    for (let i = 0; i < array.length; i++) {
      const blackOutFrom = this.datePipe.transform(array[i].blackOutFrom, 'dd-MM-yyyy');
      const blackOutTo = this.datePipe.transform(array[i].blackOutTo, 'dd-MM-yyyy');
      blackOutArray.push({
        "NAME": array[i].blackOutName,
        "DATE_FROM": blackOutFrom,
        "DATE_TO": blackOutTo
      })
    }
    let savedRateFiles = {
      "ID": this.planId,
      "CATEGORY_ID": this.ratePlanStep1.value.roomCategory,
      "TYPE_ID": this.ratePlanStep1.value.roomType,
      "MARKET_SEGMENT_ID": this.ratePlanStep1.value.marketSegment,
      "MARKET_SEGMENT_NAME": this.ratePlanStep1.value.segmentName,
      "NAME": this.ratePlanStep1.value.ratePlanName,
      "CODE": this.ratePlanStep1.value.ratePlanCode,
      "DESCRIPTION": this.ratePlanStep1.value.rateDescription,
      "STAY_CONDITION_ID": this.ratePlanStep1.value.specifyStayLength,
      "STAY_CONDITION": {
        "MIN_STAY_ID": !!this.ratePlanStep1.value.minimumDays ? this.ratePlanStep1.value.minimumDays : 0,
        "MAX_STAY_ID": !!this.ratePlanStep1.value.maximumDays ? this.ratePlanStep1.value.maximumDays : 0
      },
      "ROOM_FACILITIES_ID": this.requiredFacilities,
      "HOTEL_FACILITIES_ID": this.ratePlanStep2.value.hotelFacility,
      "DERIVED_PLAN_ID": this.ratePlanStep2.value.derivedPlan,
      "PRICING": roomTypePricing,
      "BLACKOUT": blackOutArray,
      "CHECKIN_DEPOSIT": {
        "CHECKIN_DEPOSIT_ID": this.ratePlanStep2.value.preDeposite,
        "CHECKIN_DEPOSIT_AMOUNT": this.ratePlanStep2.value.preDepositeAmount
      },
      "ADVANCED_BOOKING_ID": this.ratePlanStep2.value.preBook,
      "ADVANCED_BOOKING": {
        "ADVANCED_MIN_STAY_ID": !!this.ratePlanStep2.value.minPreBookDays ? this.ratePlanStep2.value.minPreBookDays : 0,
        "ADVANCED_MAX_STAY_ID": !!this.ratePlanStep2.value.maxPreBookDays ? this.ratePlanStep2.value.maxPreBookDays : 0
      },
      "TAX_ID": this.ratePlanStep2.value.tax,
      "CANCELLATION_POLICY_ID": this.ratePlanStep3.value.cancellation,
      "DELETED": false
    }
    this.hotelService.updateRatePlan(savedRateFiles).subscribe({
      next: (data) => {
        this.loading = false;
        this.notify.showNotification(
          data.message,
          "top",
          (!!colorObj[data.status] ? colorObj[data.status.status] : "success"),
          data.status
        );
        this.backToRoomTypes();
      },
      error: (e) => {
        this.loading = false;
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
        this.backToRoomTypes();
      }
    })
  }

  cancelRatePlan() {
    if (!this.updatePlan) {
      this.ratePlanStep1.reset();
      this.ratePlanStep2.reset();
      this.ratePlanStep3.reset();
      this.selectedTab = 0;
    }
    else {
      this.backToRoomTypes();
    }
  }

  backToRoomTypes() {
    this.ratePlanStep1.reset();
    this.ratePlanStep2.reset();
    this.ratePlanStep3.reset();
    this.router.navigateByUrl('rooms');
    this.hotelService.changeAction('');
  }

  logout() {
    const dialogRef = this.dialog.open(LogoutConfirmationComponent, {
      width: '343px',
      height: 'auto'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'yes') {
        window.localStorage.clear();
        this.router.navigate(['auth/login']);
      }
    });
  }

}