<mat-card class="matCard">
  <mat-card-header class="card-header">
    <mat-tab-group class="tab-header" [(selectedIndex)]="selectedTabIndex">
      <mat-tab label="Live Request">
        <mat-card class="table-card"></mat-card>
      </mat-tab>
      <mat-tab label="Chatbot">
        <mat-card class="table-card"></mat-card>
      </mat-tab>
    </mat-tab-group>

  </mat-card-header>
  <div>
    <div class="card-content">
      <div class="subHeader1">
        <div>Average FRT</div>
        <div class="value">- Mins</div>
      </div>
      <div class="subHeader2">
        <div>Average Time of Resolution</div>
        <div class="value">- Mins</div>
      </div>
      <div class="selector">
        <button mat-raised-button class="custom-button">
          <mat-select [(value)]="selectedYear" (selectionChange)="onYearChange($event)">
            <mat-option *ngFor="let year of years" [value]="year">{{ year }}</mat-option>
          </mat-select>
        </button>
      </div>
    </div>
    <div class="data-table">
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 custom-table">
        <ng-container matColumnDef="service">
          <th mat-header-cell *matHeaderCellDef class="table-header"> Service </th>
          <td mat-cell *matCellDef="let element"> {{element.service}} </td>
        </ng-container>
        <ng-container matColumnDef="averageFRT">
          <th mat-header-cell *matHeaderCellDef class="table-header"> Average FRT </th>
          <td mat-cell *matCellDef="let element"> {{element.averageFRT}} </td>
        </ng-container>
        <ng-container matColumnDef="averageTimeOfResolution">
          <th mat-header-cell *matHeaderCellDef class="table-header"> Average Time of Resolution </th>
          <td mat-cell *matCellDef="let element"> {{element.averageTimeOfResolution}} </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
    <!--
    <mat-card>
      <mat-card-content>Simple card</mat-card-content>
    </mat-card> -->

    <mat-card-actions></mat-card-actions>
  </div>
</mat-card>
