import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTabChangeEvent } from '@angular/material/tabs';

@Component({
  selector: 'app-customer-support',
  templateUrl: './customer-support.component.html',
  styleUrls: ['./customer-support.component.scss'],
})
export class CustomerSupportComponent {
  selectedYear: number;
  selectedTabIndex: number = 0;
  years: number[] = [2020, 2021, 2022, 2023];
  dataSource: MatTableDataSource<any>;
  displayedColumns: string[] = ['service', 'averageFRT', 'averageTimeOfResolution'];
  liveRequest: boolean = false;
  chatBox: boolean = false;

  data = {
    liveRequest: {
      // 2020: [{ service: 'Service A', averageFRT: '5 mins', averageTimeOfResolution: '15 mins' }, { service: 'Service A', averageFRT: '5 mins', averageTimeOfResolution: '15 mins' }],
      // 2021: [{ service: 'Service B', averageFRT: '6 mins', averageTimeOfResolution: '14 mins' }],
      // 2022: [{ service: 'Service C', averageFRT: '7 mins', averageTimeOfResolution: '13 mins' }],
      // 2023: [{ service: 'Service D', averageFRT: '8 mins', averageTimeOfResolution: '12 mins' }],
    },
    chatbot: {
      // 2020: [{ service: 'Service E', averageFRT: '4 mins', averageTimeOfResolution: '10 mins' }],
      // 2021: [{ service: 'Service F', averageFRT: '5 mins', averageTimeOfResolution: '11 mins' }],
      // 2022: [{ service: 'Service G', averageFRT: '6 mins', averageTimeOfResolution: '12 mins' }],
      // 2023: [{ service: 'Service H', averageFRT: '7 mins', averageTimeOfResolution: '13 mins' }],
    }
  };

  onTabChange(event: MatTabChangeEvent): void {
    this.liveRequest = false;
    this.chatBox = false;

    switch (event.index) {
      case 0:
        this.liveRequest = true;
        break;
      case 1:
        this.chatBox = true;
        break;
    }
  }
  constructor() {
    this.selectedYear = this.years[0];
    this.updateDataSource();
  }

  onYearChange(event: any) {
    this.selectedYear = event.value;
    this.updateDataSource();
  }

  // onTabChange(event: any) {
  //   this.updateDataSource();
  // }

  updateDataSource() {
    const tabKey = this.selectedTabIndex === 0 ? 'liveRequest' : 'chatbot';
    this.dataSource = new MatTableDataSource(this.data[tabKey][this.selectedYear]);
  }
}
